.w-img {
  width: 30%;
  height: auto;
}

.img-pledge{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 500px) {
  .w-img {
    width: 100%;
  }
}

body{
  background-color: #313380;
}

.profile_img{
  width: 55px;
  height: 55px;
}

.desc-center{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
}